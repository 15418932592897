import React, { memo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Drawer from '@material-ui/core/Drawer';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import { navigate } from 'gatsby';
import SearchIconImg from '~images/navbar/search-icon.png';
import Link from '~components/Link';
import { useTestId } from '~utils/set-testid';
import { getNavigationPageLink } from '~utils/common-site-helper';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import useConversionFramework from '~hooks/useConversionFramework';

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    position: 'relative'
  },
  drawerPaper: {
    position: 'absolute',
    top: 0,
    height: '100vh',
    overflowY: 'hidden',
    backgroundColor: props => (props.isDark ? '#0F0F0F !important' : '#FFFFFF')
  },
  drawerContainer: {
    overflow: 'auto',
    minWidth: '400px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100vw'
    }
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '33px'
  },
  linkText: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    justifyContent: 'flex-start',
    padding: '12px 16px',
    color: props => (props.isDark ? '#FFF' : '#000'),
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: props => (props.isDark ? '#212121' : '#f6f6f6')
    }
  },
  backdrop: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0, 0.44)'
  },
  searchInputWrapper: {
    position: 'relative',
    border: '1px solid #D9D9D8',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
    marginRight: '15px',
    marginBottom: '20px',
    borderRadius: 8
  },
  searchInput: {
    padding: '13px 16px',
    width: '100%',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '16px',
    border: '0 none',
    color: '#828182',
    backgroundColor: 'transparent',
    '&:focus': {
      border: '0 none',
      outline: '0 none'
    }
  },
  searchIcon: {
    height: '18px',
    marginLeft: '10px',
    maxWidth: '100%',
    display: 'flex',
    '-webkit-filter': props => (props.isDark ? 'invert(1)' : 'unset'),
    filter: props => (props.isDark ? 'invert(1)' : 'unset')
  }
}));

const NavbarDrawer = memo(({ items, open, onClose, onAboutClick, showAbout }) => {
  const { isMarketingSite } = useConversionFramework();
  const { jwtAvailable } = useUserContext().session;
  const classes = useStyles({ isDark: isMarketingSite });
  const signInTestId = useTestId('guest-drawer-signin-btn');

  const onSearchClick = () => navigate('/search');
  return (
    <>
      <Drawer
        variant="persistent"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper
        }}
        open={open}
        onClose={onClose}
      >
        <div className={classes.drawerContainer}>
          <div className={classes.linkContainer}>
            <div className={classes.searchInputWrapper}>
              <img src={SearchIconImg} alt="Aleph Beta Search" className={classes.searchIcon} />

              <input
                className={classes.searchInput}
                placeholder="Search videos, podcasts, and materials"
                autoComplete="off"
                onClick={onSearchClick}
              />
            </div>
            {items.map(link => (
              <Link key={link.link_title} to={link.link_url} className={classes.linkText}>
                {link.link_title}
              </Link>
            ))}
            {showAbout && (
              <ButtonBase onClick={onAboutClick} className={classes.linkText} disableRipple>
                ABOUT
              </ButtonBase>
            )}

            {!jwtAvailable && (
              <Link
                to={getNavigationPageLink('/login', isMarketingSite)}
                className={classes.linkText}
                {...signInTestId}
              >
                LOG IN
              </Link>
            )}
          </div>
        </div>
      </Drawer>
      {open && (
        <Box
          component="div"
          position="absolute"
          width="100vw"
          height="100vh"
          bgcolor="rgba(0,0,0, 0.44)"
          onClick={onClose}
        />
      )}
    </>
  );
});

NavbarDrawer.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link_title: PropTypes.string.isRequired,
      link_url: PropTypes.string.isRequired
    })
  ),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAboutClick: PropTypes.func.isRequired,
  showAbout: PropTypes.bool
};
NavbarDrawer.defaultProps = {
  items: [],
  showAbout: false
};

export default NavbarDrawer;
